.certificate-container-templatetwo {
  background-image: url('../../../assets/img/Tim-Sertifika-Revize.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.certificate-content-templatetwo {
  padding: 40px;
  width: 80%;
  text-align: center;
}

.certificate-footer-templatetwo {
  margin-top: 20px;
  background: none;
}

.certificate-footer-templatetwo .ant-row {
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.certificate-footer-templatetwo .ant-col {
  background: none;
  text-align: center;
}

.certificate-footer-templatetwo .ant-image {
  background: none;
}

.certificate-footer-templatetwo.with-logo-two .ant-col {
  margin: 0 20px; /* QR kod, logoTwo ve logo arasında boşluk bırakmak için */
}

.certificate-footer-templatetwo.with-logo-two {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.certificate-footer-templatetwo.with-logo-two .ant-row {
  justify-content: space-around; /* Logolar arasındaki boşluğu korur */
  width: 100%; /* Logoları ve QR kodunu yatayda dağıtmak için */
}

.certificate-footer-templatetwo.with-logo-two .signature-col {
  text-align: center; /* İmza kısmını ortalar */
  margin-top: 10px; /* İmza ile logolar arasında boşluk bırakmak için */
}
.logo-two-signature{
  justify-content: center !important;
}

.image-with-maxwidth {
  max-width: 120px; /* Burada istediğin genişliği belirtebilirsin */
  width: 100%; /* Bu, resmin genişliğini max-width'ten fazla olmamak şartıyla tamamen doldurmasını sağlar */
  height: auto; /* Bu, resmin en-boy oranını korur */
}

@page {
  size: A4 landscape;
  margin: 0;
}

@media print {
  body * {
    visibility: hidden;
  }

  .certificate-container-templatetwo, .certificate-container-templatetwo * {
    visibility: visible;
  }

  .certificate-container-templatetwo {
    width: 297mm;
    height: 210mm;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-print-color-adjust: exact;
  }

  .certificate-content-templatetwo h1,
  .certificate-content-templatetwo h2,
  .certificate-content-templatetwo h3,
  .certificate-content-templatetwo p {
    font-size: 22pt;
  }
}
